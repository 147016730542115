<template>
  <v-container>
    <v-data-table
      v-model="selectedItem"
      :headers="headers"
      :items="items"
      class="elevation-5 custom-table3"
      :no-data-text="$t('no_data')"
      :search="search"
      :loading="loading"
      mobile-breakpoint="0"
      show-select
      single-select
      :items-per-page="5"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <h3 class="ma-3">
            {{ $t("choose_one") + $t($route.params.type) }}
          </h3>
          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('search_txt')"
            single-line
            hide-details
            filled
            dense
            rounded
            class="mx-auto"
            style="max-width: 500px"
            clearable
          >
          </v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | moment }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at | moment }}
      </template>
      <template v-slot:[`item.show`]="{ item }">
        <v-btn icon @click="showItem(item)">
          <v-icon>remove_red_eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-data-table
      :headers="headersI"
      :items="$store.state.selected"
      class="elevation-5 custom-table mt-5"
      :no-data-text="$t('no_data')"
      :search="searchI"
      :loading="loading"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <h3 class="ma-3">
            {{ $t("total_number") }}
          </h3>
          <h2>{{ $store.state.selected.length }}</h2>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchI"
            append-icon="search"
            :label="$t('search_txt')"
            single-line
            hide-details
            filled
            dense
            rounded
            class="mx-auto"
            style="max-width: 500px"
            clearable
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | moment }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at | moment }}
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn icon color="red darken-3" @click="deleteSub(item)">
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogShow" width="700">
      <v-card>
        <v-card-text
          v-if="showContent"
          style="white-space: pre-wrap; padding-top: 20px; color: black"
        >
          <div
            v-if="showContent.text"
            :style="[isLtr ? { direction: 'ltr' } : { direction: 'rtl' }]"
          >
            {{ showContent.text }}
          </div>
          <iframe
            v-else
            onload='(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));'
            style="height: 200px; width: 100%; border: none; overflow: hidden"
            :srcdoc="showContent.html"
          ></iframe>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-switch
            v-if="showContent && showContent.text"
            v-model="isLtr"
            :label="$t('english')"
            class="me-2"
          />
          <v-btn color="primary" text @click="dialogShow = false">
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSend" max-width="550px">
      <v-card>
        <v-card-title class="headline" v-text="$t('confirm_send')" />
        <v-card-text
          v-html="
            $t('send_confirm_txt', {
              text: getShortTxt(),
              count: $store.state.selected.length,
            })
          "
        />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogSend = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" @click="send()">
            {{ $t("send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sendLoading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("please_wait_txt") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-btn
      :loading="sendLoading"
      @click="initSend()"
      fab
      dark
      large
      color="primary"
      fixed
      right
      bottom
      class="mr-5"
    >
      <v-icon dark>send</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { email, message, sendEmails, sendMessages } from "@/api/URLs";
import { getInvoicesH, getSelectedEH, getSelectedMH } from "@/api/headers";

export default {
  name: "SendPage",
  data() {
    return {
      headers: [],
      headersI: getInvoicesH(),
      items: [],
      isEmail: this.$route.params.type === "emails",
      search: null,
      searchI: null,
      loading: false,
      selectedItem: [],
      dialogShow: false,
      showContent: null,
      isLtr: false,
      dialogSend: false,
      sendLoading: false,
    };
  },
  watch: {
    "$root.$i18n.locale"() {
      this.headers = this.isEmail ? getSelectedEH() : getSelectedMH();
      this.headersI = getInvoicesH();
      this.headersI.unshift({
        text: this.$t("delete"),
        value: "delete",
        sortable: false,
      });
    },
    "$store.state.selected"(val) {
      if (val.length <= 0) this.$router.go(-1);
    },
  },
  created() {
    if (
      this.$store.state.selected.length > 0 &&
      this.$route.params.type != null
    ) {
      this.headersI.unshift({
        text: this.$t("delete"),
        value: "delete",
        sortable: false,
      });
      this.headers = this.isEmail ? getSelectedEH() : getSelectedMH();
      this.getItems();
    } else this.$router.go(-1);
  },
  methods: {
    getItems() {
      this.loading = true;
      let self = this;
      this.$http({
        method: "get",
        url: self.isEmail ? email : message,
      }).then(function (response) {
        console.log(response);
        if (response.data.success) self.items = response.data.data;
        self.loading = false;
      });
    },
    showItem(item) {
      if (item.text === undefined) this.getEmail(item);
      else {
        this.showContent = item;
        this.dialogShow = true;
      }
    },
    deleteSub(item) {
      let self = this;
      this.$store.state.selected.splice(
        self.$store.state.selected.indexOf(item),
        1
      );
    },
    getEmail(item) {
      let self = this;
      this.$http({
        method: "get",
        url: email + "/" + item.id,
      }).then(function (response) {
        console.log(response);
        if (response.data.success) {
          self.showContent = response.data.data;
          self.dialogShow = true;
        }
      });
    },
    initSend() {
      if (this.$store.state.selected.length < 1)
        this.$store.commit("showToast", "select_subscriber");
      else if (this.selectedItem.length < 1)
        this.$store.commit(
          "showToast",
          this.isEmail ? "choose_email" : "choose_message"
        );
      else this.dialogSend = true;
    },
    send() {
      if (this.selectedItem[0] === undefined) return;
      this.dialogSend = false;
      this.sendLoading = true;
      let sendItems = [];
      let itemID = this.selectedItem[0].id;
      if (this.isEmail)
        this.$store.state.selected.forEach(function (subscriber) {
          sendItems.push({
            email_id: itemID,
            subscriber_id: subscriber.smart_card,
          });
        });
      else
        this.$store.state.selected.forEach(function (subscriber) {
          sendItems.push({
            message_id: itemID,
            subscriber_id: subscriber.smart_card,
          });
        });

      let self = this;
      this.$http({
        method: "post",
        url: self.isEmail ? sendEmails : sendMessages,
        data: sendItems,
      }).then(function (response) {
        console.log(response);
        self.sendLoading = false;
        if (response.data.success) {
          self.$store.commit("showToast", "sent_successfully");
          self.$router.go(-1);
        }
      });
    },
    getShortTxt() {
      if (this.selectedItem[0] === undefined) return "";
      let txt = this.isEmail
        ? this.selectedItem[0].subject
        : this.selectedItem[0].text;

      return txt.split(" ").slice(0, 5).join(" ");
    },
  },
};
</script>

<style scoped></style>
